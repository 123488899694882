<template>
  <div class="main">
    <heads></heads>
    <banners></banners>
    <div class="content">
      <div class="xtjs">
        <div class="xtjs-title">系统介绍</div>
        <div class="xtjs-con">
          <p>加合论文投稿系统，基于自开发模型，依托大数据支撑，精解论文属性，实现投稿论文与目标期刊的最优匹配，简化了作者直接投稿流程，解决了作者与期刊社之间的投稿沟通与交流问题。</p>
          <p>清理并分析期刊文献的字、词、句、段等属性，分解为“元组块”，构造“元颗粒”，计算“元特征值”，建立“元向量”，构建“元关系”。</p>
          <p>系统全面收集期刊的公开文献数据，将文献的字、词、句、段等属性进行了一系列分析与处理。通过细化分解 “元组块”，构建了明晰的“元颗粒”，计算了精准的“特征值”，并进一步建立了高效的“元向量”，构建了紧密的“元关系”。为论文的文本向量化提供了深度挖掘的基础，也为期刊的高度智能化提供了坚实可靠的支持。</p>
          <p>系统作为作者的辅助工具能够协助完成论文选题、标题优化、参考文献推荐、文本查重、图片查重、引文格式规范、中图分类号推荐等任务，使之符合各种期刊的要求。润色和优化不仅使得论文写作的过程更加顺畅，也提高了投稿的效率和成功率。</p>
          <p>同时，系统还能够根据期刊的发文领域、发文地域、机构特征等特点，为作者提供个性化的期刊推荐服务。系统根据论文的特点和作者的需求，智能匹配最合适的期刊。作者无需繁琐地搜索合适的期刊，极大地简化了投稿流程，节省了宝贵的时间和精力。</p>
          <p>此外，系统还提供了直接与期刊编辑部对接的功能。向作者提供期刊详细的投稿要求、审稿流程、联系方式等信息，更加精准地满足了作者的需求。这种直接对接的方式，避免了繁琐的往复沟通，实现了高效的投稿工作。</p>
          <p>让作者可以更加专注于学术研究，而不用花费过多的时间和精力在投稿流程上，极大地提升学术质量和研究效率！</p>
        </div>
      </div>
    </div>

    <footers></footers>
  </div>
</template>

<style scoped>
.main {
  background: #fbfcff;
}
.content {
  width: 1200px;
  height: auto;
  padding: 40px 0;
  margin: auto;
}
.xtjs-title{
  font-size: 24px;
  text-align: center;
}
.xtjs-con{
  padding: 10px 0;
}
.xtjs-con p{
  text-indent: 32px;
  text-align: justify;
}
</style>
